import React from "react";
import GooglePlayImage from "../assets/image/google_play.png"
import IOSImage from "../assets/image/ios.png"

export const Header = (props) => {
  const APKUrl = "https://play.google.com/store/apps/details?id=host";
  const IOSUrl = "https://apps.apple.com/us/app/expo-go/id982107779";
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div>
                  <img onClick={() => {window.open(APKUrl, "_blank")}} className="action_button_cc" src={GooglePlayImage} alt="Google play button" />
                  <img onClick={() => {window.open(IOSUrl, "_blank")}} className="action_button_cc" src={IOSImage} alt="Ios button" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
